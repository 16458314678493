import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import krusidull3 from '../../static/krussidull3-white.webp';
import todounravel from '../../static/todounravel.jpg';

function Produkt4() {
    useEffect(() => {
        document.title = "TO DO Unravel | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={todounravel} width="100px" height="auto" alt="TO DO Growth logo"></img>
                <div class="text-wrapper">
                    <h1>Plattform TO DO Unravel® | Nu löser vi trassel.</h1>
                </div>
            </div>
            </First>

            <Second>
            <div class="produktBody">
                <h2>För vem</h2>
                <p>
                     Företag, samverkansorganisationer, myndigheter regionorganisationer och
                    kommuner; när komplexa frågor måste lösas tillsammans, när vanlig samverkan inte
                    räcker till.
                </p>
                <h2 style={{marginTop: "20px"}}>Vad</h2>
                <p>
                    TO DO Unravel, är framtagen med ett syfte, att lösa trassliga problem. Problem
                    som sitter fast. Sitter fast i varandras organisationer och hindrar framväxten av
                    gemensamma nya lösningar. Några exempel på samägda utmaningar är tidiga faser av
                    infrastrukturutveckling, 2030-problematik, utveckling och innovationsarbeten som
                    måste göras tillsammans, och problem som fastnat mellan företag och kommuner.
                    <br/><br/>
                    TO DO Unravel, möjliggör att flera olika organisationer arbetar på distans, fast
                    tillsammans för att lösa trassel. Våra digitala verktyg och processer strukturerar och
                    skapar framdrift. Att alla kan arbeta på distans, gör plattformen speciellt lämpad när
                    en kommun många måste samarbeta för att hitta gemensamma vägar framåt.
                    <br/><br/>
                    Plattformen är framtagen i ett forskningsarbete tillsammans med Region Gävleborg,
                    Tillväxtverket och Gävle Högskola, med EU-finansiering. Målet med
                    plattformen är att offentliga organisationer själva arbetar med den efter träning och
                    utbildning.
                </p>
                <h2 style={{marginTop: "20px"}}>Hur</h2>
                <p>
                    Processhjälp för att lösa trassel kan ordnas av Invite TO DO, men målet med TO
                    DO Unravel-plattformen är att företag och offentliga organisationer tränas och
                    utbildas för att själva lösa trassel, på ett strukturerat sätt över tid.
                </p>
                
                
                
            </div>
            </Second>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Produkt4;