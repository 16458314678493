import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from "react";

import casePicture from '../../static/case.webp';
import trasselSide from '../../static/case.webm';

function Case1() {
    useEffect(() => {
        document.title = "Case: näringslivsstrategi för tillväxt. | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={casePicture} width="200px" height="auto" alt="Näringslivsstrategi för tillväxt" />
                <div class="text-wrapper">
                    <h1>Näringslivsstrategi för tillväxt</h1>
                    <p class="subHeading" style={{ marginBottom: '0px' }}>2023</p>
                </div>
            </div>
            </First>

            
            <div class="caseContainer">
                <div class="trasselSide">
                    <video playsInline autoPlay muted src={trasselSide} type="video/mp4"></video>
                </div>
                <Second>
                <div class="caseBody">
                    <h2>Vad var problemet?</h2>
                    <p>
                        En kommun vill strukturera sitt arbete med näringslivet på ett bättre sätt. Man upplever att
                        arbetet med etableringar och tillväxt för företagen måste bli smidigare och enklare. Man vill också
                        bygga gemensam förståelse och sätta både strategi och prioriteringar, samt välja arbetssätt för att
                        skapa tillväxt i kommunen. Många olika parter, med olika mål och förutsättningar saknar framdrift
                        för gemensamt utvecklingsarbete.
                    </p>

                    <h2>Vilken metod använde vi?</h2>
                    <p>
                        TO DO Growth® i Näringslivstillväxt.
                    </p>

                    <h2>Vilka deltog?</h2>
                    <p>
                        Politisk ledning, kommunledning, företag och organisationer.
                    </p>

                    <h2>Vad blev resultatet?</h2>
                    <p>
                        Med ett horisontellt arbetssätt skapas en gemensam förståelse och tillit till varandras
                        infallsvinklar för att kunna åstadkomma utveckling tillsammans. Gruppen tar därefter fram nya
                        arbetssätt, prioriteringar och gemensam framtidsstrategi som bygger på lokala förutsättningar och
                        möjligheter. Man lägger en struktur för tillväxt – tillsammans.
                    </p>
                </div>
                </Second>
            </div>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Case1;