import React, { useState } from 'react';

class Tb1 extends React.Component {
    render() {
        return (
            <div class="animation purple">
                <h2>Verktyg för samutveckling.</h2>
                <p>
                    <b>
                    Goda idéer kan ibland fastna på whiteboardtavlan som gula post-it lappar.
                    </b>
                    <br/><br/>
                    Med vår struktur går det ofta lite bättre - och man kommer längre. Väl beprövade arbetssätt skapar
                    framdrift på ett ordnat och kreativt sätt. Steg för steg växer nya samarbeten, produkter och tjänster
                    fram som svar på det tilltrasslade.
                </p>
            </div>
        )
    }
}

export default Tb1;