import React, { useState } from 'react';

class FirstText extends React.Component {
    render() {
        return (
            <div class="fromBottom leftAlign">
                <h2>Vi tror på individen som kraft.</h2>

                <p>
                    <b>
                    Många drivs av lust att skapa och utveckla idéer, produkter och tjänster inom organisationens
                    väggar. Det är att vara intraprenör. Som en entreprenör, fast inom organisationer.
                    </b>
                    <br/><br/>
                    Den stora fördelen med intraprenörer, är att de har tillgång till en fungerande organisation som
                    erbjuder ekonomiska och praktiska möjligheter, för genomförande av nya idéer.
                    <br/><br/>
                    Det är hos intraprenören den slumrande resursen finns.
                    <br/><br/>
                    De flesta organisationer har vad som krävs i form av kraftfull förnyelse och initiativförmåga, för att
                    mobilisera mot omvärlden ständiga krav på förändringar.
                    <br/><br/>
                    Många söker utanför organisationen, när lösningen i själva verket finns hos dem själva. Att
                    organisera, strukturera och systematisera ett konstant flöde av idéer är lösningen, både på en
                    strategisk och taktisk nivå. Lösningar finns oftast inom organisationen eller i samarbete med andra
                    organisationer.
                </p>
            </div>
        )
    }
}

export default FirstText;