import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import styled, { keyframes } from 'styled-components';

class Fifth extends React.Component {
    componentDidMount() {
        document.getElementById("bg").style.backgroundColor = "#c6d8d4";
        document.getElementById("curve").style.backgroundColor = "#c6d8d4";
    }

    render() {
        return (
            <div class="green">
                <h2>
                <TypeAnimation
      sequence={[
        'Gedigen underbyggnad med egen forskning.', // Types 'One'

        () => {
          console.log('Sequence completed'); // Place optional callbacks anywhere in the array
        }
      ]}
      cursor={true}
      repeat={0}
    />
                </h2>
                <Reveal>

                    <p style={{marginTop: "20px"}}>
                    Hur får man en organisation att ta steget att utveckla?
                    <br/><br/>
                    Vårt arbetssätt bygger på människors förmåga att utveckla idéer i sin befintliga yrkesroll. Alla
                    människor har idéer, och vi adderar sätt att genomföra dem på ett enkelt sätt.
                    <br/><br/>
                    Vår forskning har utarbetat metoder och processer för att ta vara på idéer som skapar drivkraft.
                    Tillsammans med kunder har de finjusterats till kraftfulla verktyg för samutveckling.
                    <br/><br/>
                    De senaste åren har Invite To Do vidareutvecklats i samarbete med aktörer i Gävleborg med stöd av
                    Tillväxtverket, och Högskolan i Gävle, i syfte att utveckla horisontellt ledarskap. Allt för att göra det
                    enkelt att förändra, förnya och utveckla - tillsammans.
                    </p>                    
                </Reveal>
            </div>
        )
    }
}

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Reveal = styled.span`
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

export default Fifth;