import React, { useState } from 'react';

import Logo from '../static/inv_logo_pink.png';

class Footer extends React.Component {
    render() {
        return (
            <div class="footer" id="footer">
                <div>
                    <img src={Logo} width="160px" height="auto" alt="InviteToDo logo"></img>
                    <br/><br/>
                    <p>
                        <b>InviteToDo är en plattform för att ta idéer framåt, lösa komplexa problem och utveckla idéer.</b>
                        
                    </p>
                    <br/>
                    mikael@invitetodo.com
                    
                </div>
                <div>
                    <h2>Case</h2>
                    <a href="/case/naringslivsstrategi">Näringslivsstrategi</a>
                    <br/>
                    <a href="/case/fem-kommuner">Fem kommuner</a>
                    <br/>
                    <a href="/case/frustration-till-samutveckling">20 Företag</a>
                    <br/>
                    <a href="/case/logistikkoncept">Företag, myndigheter och kommun</a>
                    <br/>
                    <a href="/case/recyclingsystem">Recyclingsystem</a>
                </div>
                <div>
                    <h2>Produkter & Tjänster</h2>
                    <a href="/produkter/webbinar">Webbinar</a>
                    <br/>
                    <a href="/produkter/processtod">Processtöd</a>
                    <br/>
                    <a href="/produkter/todogrowth">TO DO Growth®</a>
                    <br/>
                    <a href="/produkter/todounravel">TO DO Unravel®</a>
                    <br/>
                    <a href="https://account.invitetodo.com/apps/community/">Community BETA</a>
                    <br/><br/>
                    <p>InviteToDo AB © 2024</p>
                </div>
            </div>
        )
    }
}

export default Footer;