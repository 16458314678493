import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import casePicture from '../../static/case.webp';
import trasselSide from '../../static/case.webm';

function Case5() {
    useEffect(() => {
        document.title = "Case: Samutveckla recyclingsystem för byggvaror med många parter. | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={casePicture} width="200px" height="auto" alt="Näringslivsstrategi för tillväxt" />
                <div class="text-wrapper">
                    <h1>Samutveckling av recyclingsystem för byggvaror med många parter.</h1>
                    <p class="subHeading" style={{ marginBottom: '0px' }}>2023</p>
                </div>
            </div>
            </First>

            
            <div class="caseContainer">
                <div class="trasselSide">
                    <video playsInline autoPlay muted src={trasselSide} type="video/mp4"></video>
                </div>
                <Second>
                <div class="caseBody">
                    <h2>Vad var problemet?</h2>
                    <p>
                        Ett helt län med flera kommuner och många företag samt intresseorganisationer har
                        bestämt sig för att förbättra sitt ”footprint” genom att starta recykling av byggvaror. Problemet är
                        väldigt tilltrasslat med många olika problem i ett större problem. Det är allt från offentliga
                        upphandlingsförfaranden till kommuner med svårigheter att hitta aktörer som kan göra rätt till
                        arkitekter och konstruktörer med ambitioner men som saknar sammanhang för att driva på
                        utvecklingen i rätt riktning. Det saknas både ett sammanhållande koncept, ett sammanhang och att
                        rikta energin av alla aktörer med en gemensam strategi.
                    </p>

                    <h2>Vilken metod använde vi?</h2>
                    <p>
                        TO DO Unravel® | Trassel till Struktur.
                    </p>

                    <h2>Vilka deltog?</h2>
                    <p>
                        15 företag, 4 kommuner och flera organisationer.
                    </p>

                    <h2>Vad blev resultatet?</h2>
                    <p>
                        Resultatet blev ett förslag på en struktur på ett cirkulärt system med ett antal innovativa
                        delar som därefter skall utvecklas i flera utvecklingsprocesser. Vi startade med att lösa själva trasslet:
                        skapa en tydlig struktur i stället för väldigt många lösa trådar och goda idéer. På nio veckor skapades
                        ett gemensamt momentum framåt, med samlande koncept och en gruppering som därefter
                        bestämde sig för att söka offentlig finansiering för att starta innovationsprojekt tillsammans.
                    </p>
                </div>
                </Second>
            </div>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Case5;