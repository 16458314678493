import React, { useState } from 'react';

class Case extends React.Component {
    render() {
        return (
            <div class="container">
                <div class="casesContainer">
                    
                </div>
            </div>
        )
    }
}

export default Case;