import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import casePicture from '../../static/case.webp';
import trasselSide from '../../static/case.webm';

function Case2() {
    useEffect(() => {
        document.title = "Case: Fem kommuner utvecklar sina näringsliv - tillsammans. | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={casePicture} width="200px" height="auto" alt="Näringslivsstrategi för tillväxt" />
                <div class="text-wrapper">
                    <h1>Fem kommuner utvecklar sina näringsliv - tillsammans</h1>
                    <p class="subHeading" style={{ marginBottom: '0px' }}>2023</p>
                </div>
            </div>
            </First>

            
            <div class="caseContainer">
                <div class="trasselSide">
                    <video playsInline autoPlay muted src={trasselSide} type="video/mp4"></video>
                </div>
                <Second>
                <div class="caseBody">
                    <h2>Vad var problemet?</h2>
                    <p>
                        Fem små kommuner har vill utvecklas tillsammans, men saknar arbetsmetoder och verktyg.
                        Kommunerna har gjort analysen att man var för sig är för små, har för lite resurser och saknar
                        arbetssätt för att ta sig framåt och växa var för sig och tillsammans för att öka sina skatteunderlag
                        samt utveckla kommunerna – tillsammans. Kommunerna behöver hitta nya vägar för att utvecklas
                        trots motvind. De söker nya arbetssätt, metoder och processer för att kunna arbeta mer strukturerat
                        och resultatorienterat över lång tid.
                    </p>

                    <h2>Vilken metod använde vi?</h2>
                    <p>
                        TO DO Growth® | Näringslivstillväxt.
                    </p>

                    <h2>Vilka deltog?</h2>
                    <p>
                        Fem kommuner med tjänstemän, politiker och företag.
                    </p>

                    <h2>Vad blev resultatet?</h2>
                    <p>
                        I detta omfattande arbete som kommer att pågå under tre år utvecklas tillväxtstrategier,
                        kluster av företag, nya ekosystem av företag och lösningar på tilltrasslade problem. Helt kort,
                        använder sig kommunerna av hela plattformen för olika syften idéer, utmaningar och problem, med
                        den övergripande tanken att komma framåt.
                    </p>
                </div>
                </Second>
            </div>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Case2;