import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import krusidull1 from '../static/krussidull1.png';

import gavleborg from '../static/region_gavleborg.png';
import invLogoPink from '../static/inv_logo_pink.png';
import movexum from '../static/movexum.png';

function InnovationsVeckan() {
    const [email, setEmail] = useState(''); // State for managing email input

    useEffect(() => {
        document.title = "Innovationsveckan | InviteToDo";

        // Dynamically load Mailchimp script
        const script = document.createElement('script');
        script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="pagePadding">
            <First>
                <div className="case">
                    <div className="text-wrapper">
                        <h1>Anmälan till SKR / Innovationsveckan</h1>
                        <p>Samarrangemang: Region Gävleborg, Invite TO DO & Movexum</p>
                    </div>
                </div>
            </First>
            <Second>
                <div className="pageText" style={{ minHeight: "70vh" }}>
                    <div className="two-columns">
                        <div className="caseExcerpt">
                            <h2>Tid & Plats</h2>

                            <p className="marginParagraph">
                                <b>Tid:</b>
                                <br />
                                Onsdag 2/10 Kl. 13:00 - 15:00
                                <br />
                                <b>Mer info / frågor:</b>
                                <br />
                                mikael@invitetodo.com
                            </p>
                            <p className="marginParagraph">
                                <b>Mer info:</b>
                                <br />
                                <a className="regular" href="https://skr.se/skr/naringslivarbetedigitalisering/forskningochinnovation/innovation/innovationsveckan2024.27258.html" target="_blank" rel="nofollow noreferrer">SKR's hemsida</a>
                            </p>
                            <p className="marginParagraph">
                                <b>Fysisk plats:</b>
                                <br />
                                Movexum Gävle
                                <br />
                                Norra Kungsgatan 1
                                <br />
                                803 20, Gävle
                            </p>

                            <p className="marginParagraph">
                                <b>Digital länk:</b>
                                <br />
                                Skickas ut till de deltagare som anmält sig till seminariet här.
                            </p>

                            <img src={krusidull1} width="100%" height="auto" alt="krussidull" />
                        </div>

                        <div className="caseExcerpt">
                            <h2>Horisontellt ledarskap är svaret på komplexa problem:</h2>
                            <p>
                                Ett seminarium om hur horisontellt arbetssätt löser komplexa problem med ny digital plattform och utbildning.
                            </p>

                            <h3>Programinnehåll:</h3>

                            <p className="marginParagraph">
                            Seminariet behandlar hur horisontellt arbetssätt löser komplexa problem med ny digital plattform och utbildning, som utvecklats i Gävleborg.
                            </p>

                            <p className="marginParagraph">
                            "Komplexa problem” uppstår ofta när nya direktiv, policys och politiska direktiv drivs igenom (vertikalt) och uppifrån. Det orsakar ofta målkonflikter och hanteras också vertikalt eller genom samverkan. Men oftast är det ett otillräckligt arbetssätt. Det blir trassel i stället.
                            </p>

                            <p className="marginParagraph">
                            Det måste lösas horisontellt med flera parter aktivt inblandade, och det kräver strukturerade arbetssätt. Kommuner och regioner som ska möta samhällsutmaningar och arbeta med trassliga problem såsom omställning, hållbar utveckling, samhällsbyggnad, regional utveckling och näringslivsfrågor – måste arbeta tillsammans.
                            </p>

                            <p className="marginParagraph">
                            Gemensamt för alla trassel är att många samäger problemet. Lösningen måste därför samorganiseras, samutvecklas och kanske till och med samägas över tid för att skapa omställning, utveckling och hållbar utveckling.
                            </p>

                            <h3>Målgrupp:</h3>
                            <p>Vi riktar oss till Verksamhetsledare, Processledare, Projektledare och Strateger i Sveriges Kommuner och Regioner.</p>

                            <h3>Anmäl dig här:</h3>
                            {/* Styled Mailchimp Embed Form */}
                            <StyledMailchimpForm id="mc_embed_signup">
                                <form action="https://invitetodo.us13.list-manage.com/subscribe/post?u=753fd27b96f0b5dc581f6f642&amp;id=578afa1813&amp;f_id=001fece1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                    <div id="mc_embed_signup_scroll">
                                        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                                        <div className="mc-field-group">
                                            <label htmlFor="mce-EMAIL">Mejladress <span className="asterisk">*</span></label>
                                            <input
                                                type="email"
                                                name="EMAIL"
                                                className="required email"
                                                id="mce-EMAIL"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div id="mce-responses" className="clear foot">
                                            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                        </div>
                                        <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                                            <input type="text" name="b_753fd27b96f0b5dc581f6f642_578afa1813" tabIndex="-1" value="" />
                                        </div>
                                        <div className="optionalParent">
                                            <div className="clear foot">
                                                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Skriv upp!" />
                                                <p style={{ margin: '0px auto' }}>
                                                    <a href="http://eepurl.com/iVqEjE" title="Mailchimp - email marketing made easy and fun">
                                                        <span style={{ display: 'inline-block', backgroundColor: 'transparent', borderRadius: '4px' }}>
                                                            <img className="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style={{ width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center' }} />
                                                        </span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </StyledMailchimpForm>
                            {/* End Mailchimp Embed Form */}

                            <div className="three-columns">
                                <img src={gavleborg} width="100%" height="auto" alt="Region Gävleborg logo" />
                                <img src={invLogoPink} width="100%" height="auto" alt="InviteToDo logo" />
                                <img src={movexum} width="100%" height="auto" alt="Movexum logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </Second>
        </div>
    );
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`;

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`;

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`;

// Styled Component for Mailchimp Form
const StyledMailchimpForm = styled.div`
    #mc_embed_signup {
        background: #fff;
        clear: left;
        font: 14px Helvetica, Arial, sans-serif;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .mc-field-group {
        margin-bottom: 15px;
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }

    input[type="email"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
    }

    .button {
        background-color: #5c004f;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 4px;
    }

    .button:hover {
        background-color: #e6658b;
    }

    .optionalParent {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .refferal_badge {
        display: none !important;
    }

    .indicates-required {
        display: none;
    }

`;

export default InnovationsVeckan;