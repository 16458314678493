import React, { useState } from 'react';

import First from '../components/texts/First';
import Second from '../components/texts/Second';
import Third from '../components/texts/Third';
import Fourth from '../components/texts/Fourth';
import Fifth from '../components/texts/Fifth';
import Sixth from '../components/texts/Sixth';

import arrowRight from '../static/arrow.png';
import arrowLeft from '../static/arrow-left.webp';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {id: "1"};
    }

    handleArrowClick(action){

        if(action === 'prev'){
            if(this.state.id > '1'){
                this.setState((prevState) => ({ id: (+prevState.id - 1).toString()}));
            } else {
                this.setState( { id: "3"});
            }
        }

        if(action === 'next'){
            if(this.state.id < '3'){
                this.setState((prevState) => ({ id: (+prevState.id + 1).toString()}));
            } else {
                this.setState( { id: "1"});
            }

        }

    }

    render() {
        return (
            <div class="bgContainer" id="bg">
                <div class="arrow" id="prev" onClick={() => this.handleArrowClick('prev')}><img src={arrowLeft} width="100%" height="auto" alt="arrow icon"></img></div>

                <div class="whiteBg">
                    {(this.state.id === "1") && <First />}
                    {(this.state.id === "2") && <Second />}
                    {(this.state.id === "3") && <Third />}
                </div>

                <div class="arrow" id="next" onClick={() => this.handleArrowClick('next')}><img src={arrowRight} width="100%" height="auto" alt="arrow icon"></img></div>
            </div>
        )
    }
}

export default About;