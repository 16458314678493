import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from "react";

import Team from '../components/Team';
import beta1 from '../static/beta1.png';

function Om() {
    useEffect(() => {
        document.title = "Om InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <div class="text-wrapper">
                    <h1>Om InviteToDo</h1>
                    <p>InviteToDo är en plattform för att ta idéer framåt.</p>
                </div>
            </div>
            </First>
            <Second>
            <div class="pageText">
                <h2>Historien bakom Invite To Do</h2>
                <p>
                Idén till Invite TO DO kom redan 2007 när grundaren Mikael Scherdin, doktorerade
                vid Uppsala universitet. Scherdin doktorerade på ekonomiska ekosystem; varför
                vissa kluster av företag uppstår, exempelvis Silicon Valley, medan andra grupper av
                företag på geografiska platser dör ut. Forskningsarbetet lade grunden till de
                akademiskt vedertagna analysmodeller som nu vidareutvecklats av Invite TO DO.
                Ytterligare ett forsknings och utvecklingsarbete genomfördes mellan åren 2019 –
                2022, där flera av medarbetarna involverades i vårt team. Ett forskningsarbete som
                finansierades av Tillväxtverket, Region Gävleborg, med syfte att utveckla nya
                metoder och ett digitalt stödsystem, för att lösa komplexa problem.
                Utvecklingsarbetet har fortsatt i nära samarbete med kunder för att förfina verktyg,
                metoder och arbetssätt som samlats i en plattform.
                </p>
                

                <div class="intro">
                    <img src={beta1} width="100%" height="auto" alt="En screenshot på Invite To Do's digitala plattform"></img>
                    <div>
                        <h2>InviteToDo's egna plattform</h2>
                        <p>Den digitala plattformen har utvecklats under flera år, med en början som ett
                        forskningsprojekt som därefter blev ett utvecklingsprojekt tillsammans med kunder. I
                        arbetet med våra appar och databassystem, utvecklades också vår filosofi att all kod
                        är vår kod. Vi ville vara säkra på att inte överraskas i framtiden. Alla data lagras
                        också hos oss. Detta för att vara så säkra som vi bara kan att våra kunders idéer och
                        unika lösningar stannar hos oss. Det är en viktig del av vår övergripande IP-strategi.
                        Vårt team utvecklar nu plattformen vidare, i takt med nya kundkrav och omvärldens
                        förändringar. Bland annat skall vi ta en titt på vad AI kan tillföra.</p>
                    </div>
                </div>

                <h2 style={{textAlign: "center", marginBottom: "50px"}}>Teamet</h2>
                <Team />
                
            </div>
            </Second>

            
        </div>
    );
}
const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`
    
export default Om;    