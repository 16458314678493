import React, { useState } from 'react';

class SecondText extends React.Component {
    render() {
        return (
            <div class="fromBottom leftAlign">
                <h2>Gedigen underbyggnad med egen forskning.</h2>

                <p>
                    <b>
                    Hur får man en organisation att ta steget att utvecklas tillsammans med andra?
                    </b>
                    <br/><br/>
                    Vårt arbetssätt bygger på människors förmåga att utveckla idéer i sin befintliga yrkesroll. Alla
                    människor har idéer, och vi adderar sätt att genomföra dem på ett enklare sätt.
                    <br/><br/>
                    Vår forskning har utarbetat metoder och processer för att ta vara på idéer som skapar drivkraft.
                    Tillsammans med kunder har de finjusterats till kraftfulla verktyg för samutveckling.
                    <br/><br/>
                    De senaste åren har Invite To Do vidareutvecklats i samarbete med aktörer i Gävleborg med stöd av
                    Region Gävleborg, Tillväxtverket, och Högskolan i Gävle, i syfte att underlätta för snabbare
                    omställning och förändringar i samhället. Vi kallar det horisontellt ledarskap. Allt för att göra det
                    enkelt att förändra, förnya och utvecklas - tillsammans.
                </p>
            </div>
        )
    }
}

export default SecondText;