import React from 'react';
import styled, { keyframes } from 'styled-components';

import Program from '../components/Program';
import About from '../components/About';
import Team from '../components/Team';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Individ from '../components/Individen';
import Mikael from '../components/Mikael';
import Case from '../components/Case';

import Trassel from '../static/comp.webm';
import Bg from '../static/small_header.webm';

function Home() {
    function backToTop() {
        window.scrollTo(0, 0);
      }
    
    return (
    <div>
        

        <section>
        
        <div class="overlap">
            
            <video playsInline autoPlay muted loop class="fullscreen" src={Bg} type="video/mp4"></video>
            <video playsInline autoPlay muted class="fullscreen top" src={Trassel} type="video/mp4"></video>
        </div>
            
        
        

        

        <div class="content">
            <div>
            <First>
            <h1>Från trassel till struktur på 9 veckor.</h1>
            </First>
            <Second>
            <p>
                <b>Ett problem kan vara enkelt eller svårt. Stort eller litet. Just nu eller i framtiden. Mitt eller ditt.
                Högt eller lågt. Tidskrävande. Eller kanske allt på samma gång, med en massa olika trådar. Det är
                ett trassel.</b>
                <br /><br />
                Våra processer är strukturerade och kreativa på samma gång, vilket är svaret på det tilltrasslade. Vi
                arbetar systematiskt och stegvis med strukturerad kreativitet. Där specifika metoder öppnar vägar
                framåt. Vi skapar samägda lösningar, på samägda idéer och problem.
                </p>
            </Second>
            </div>

        </div>
        </section>

        <Mikael />
        <About />
        <Program />
        <Individ />
        

        <div class="back-to-top animation" onClick={backToTop}>↑</div>
    </div>
    );
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 4s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 3s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
`

const Third = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 4s;
    animation-fill-mode: forwards;
`
    
export default Home;    