import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import krusidull1 from '../static/krussidull1.png';
import krusidull2 from '../static/krussidull2.png';
import krusidull3 from '../static/krussidull3.png';
import krusidull4 from '../static/krussidull4.png';

function CasePage() {
    useEffect(() => {
        document.title = "Case | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <div class="text-wrapper">
                    <h1>Case</h1>
                    <p>InviteToDo har jobbat kontinuerligt för att ta fram publika case.</p>
                </div>
            </div>
            </First>
            <Second>
            <div class="pageText">
                <div class="four-columns">
                    <a href="/case/naringslivsstrategi">
                        <div class="caseExcerpt">
                            <h2>Näringslivsstrategi för tillväxt.</h2>
                            <p>
                            Här använde en kommun TO DO Growth® för att förbättra näringslivsarbetet. Genom samarbete mellan ledning, företag och organisationer skapas en gemensam tillväxtstrategi, stärkande förståelse och tillit.
                            </p>

                            <img src={krusidull1} width="100%" height="auto" alt="krussidull"/>

                            <a class="case" href="/case/naringslivsstrategi">Läs</a>
                        </div>
                    </a>

                    <a href="/case/fem-kommuner">
                        <div class="caseExcerpt">
                            <h2>Fem kommuner utvecklar sina näringsliv - tillsammans.</h2>
                            <p>
                            Fem små kommuner, tidigare begränsade av storlek och resurser, samarbetar nu för att växa tillsammans med hjälp av TO DO Growth®.
                            </p>

                            <img src={krusidull2} width="100%" height="auto" alt="krussidull"/>

                            <a class="case" href="/case/fem-kommuner">Läs</a>
                        </div>
                    </a>

                    <a href="/case/frustration-till-samutveckling">
                        <div class="caseExcerpt">
                            <h2>20 Företag går från frustration till samutveckling.</h2>
                            <p>
                            Företag i ett län, begränsade av råvarubrist för produktion av högkvalitativa trävaror, har övervunnit utvecklingshinder genom TO DO Growth®.
                            </p>

                            <img src={krusidull3} width="100%" height="auto" alt="krussidull"/>

                            <a class="case" href="/case/frustration-till-samutveckling">Läs</a>
                        </div>
                    </a>

                    <a href="/case/logistikkoncept">
                        <div class="caseExcerpt">
                            <h2>Företag, myndigheter och kommun utvecklar logistikkoncept för ny stadsdel.</h2>
                            <p>
                                Här använde företag, myndigheter och kommun TO DO Unravel® för att bygga en ny stadsdel. Ett gemensamt koncept tas fram, som i sin tur strukturerar utmaningarna.
                            </p>

                            <img src={krusidull4} width="100%" height="auto" alt="krussidull"/>

                            <a class="case" href="/case/logistikkoncept">Läs</a>
                        </div>
                    </a>

                    <a href="/case/recyclingsystem">
                        <div class="caseExcerpt">
                            <h2>Samutveckla recyclingsystem för byggvaror med många parter.</h2>
                            <p>
                            Ett län med flera kommuner och organisationer använder TO DO Unravel® för att förbättra återvinning av byggmaterial. 
                            </p>

                            <img src={krusidull4} width="100%" height="auto" alt="krussidull"/>

                            <a class="case" href="/case/recyclingsystem">Läs</a>
                        </div>
                    </a>
                </div>

                
            </div>
            </Second>

            
        </div>
    );
}
const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`
    
export default CasePage;    