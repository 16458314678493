import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import styled, { keyframes } from 'styled-components';

class Second extends React.Component {
    componentDidMount() {
        document.getElementById("bg").style.backgroundColor = "#c6d8d4";
        document.getElementById("curve").style.backgroundColor = "#c6d8d4";
    }

    render() {
        return (
            <div class="green leftAlign">
                <h2>
                <TypeAnimation
      sequence={[
        'Plattform för samutveckling av intrasslade utmaningar.', // Types 'One'

        () => {
          console.log('Sequence completed'); // Place optional callbacks anywhere in the array
        }
      ]}
      cursor={true}
      repeat={0}
    />
                </h2>
                <Reveal>

                    <p style={{marginTop: "20px"}}>
                    <b>Processarbete med komplexa problem, där många parter måste arbeta tillsammans, kräver
                    konkreta verktyg för framdrift som också har en samlande funktion. Vår plattform samlar allt på en
                    plats.</b>
                    <br/><br/>
                    Vi arbetar med kunder som både måste, och vill ta steget att samutveckla lösningar.
                    <br/><br/>
                    Våra kunder vill ofta arbeta mer aktivt med hållbarhetsarbete, lösa problem med många inblandade,
                    eller pro-aktiv näringslivsutveckling. Helt enkelt: utvecklingsarbete med många inblandade.
                    <br/><br/>
                    Våra kunder som samutvecklar lösningar ur problem och idéer med många parter ställer helt andra
                    krav på struktur och systematik, utan att tappa det kreativa.
                    <br/><br/>
                    Vår plattform garanterar strukturerad framdrift där många arbetar tillsammans för att lösa trassel.
                    </p>                    
                </Reveal>
            </div>
        )
    }
}

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Reveal = styled.span`
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

export default Second;