import React, { useState } from 'react';

import MikaelPic from '../static/Mikael.jpg';
import Signatur from '../static/signatur.webp';

class Mikael extends React.Component {
    render() {
        return (
            <div class="container">
                <div class="mikael">
                    <div>
                        <img src={MikaelPic} width="100%" height="auto" alt="En bild på Mikael Scherdin från InviteToDo AB"></img>
                    </div>
                    <div>
                        <h2 class="">Mikael Scherdin</h2>
                        <p class="subHeading">Grundare och VD</p>

                        <p>
                            Jag har forskat, skrivit böcker, sprungit medeldistans, drivit företag och konstkollektiv. Kort
                            sagt, olika erfarenheter. Vårt företag har olika som filosofi. Olika, som är grunden till all
                            kreativitet. Vi lyfter kreativiteten när det är som svårast. När flera måste samarbeta. När
                            många olika organisationer måste samarbeta för att komma framåt, kan det bli trassligt.
                            Utmaningar som måste lösas tillsammans saknas inte: 2030. Hållbarhet. Innovation.
                            Näringslivsutveckling. Komplexa problem. Få små företag att växa tillsammans. Regional
                            utveckling. Ja, listan på komplexa problem kan bli lång.
                            Jag har sammanfattat all min forskning, alla mina erfarenheter, alla mina kunskaper och
                            idéer i Invite TO DO.
                            <br/><br/>
                            Vi har arbetssätten, processerna, digitala verktyg och metoderna för att lösa det komplexa. Välkomna!
                        </p>
                        <p class="mikael-sign">
                            <img src={Signatur} width="200px" height="auto" alt="Mikael Scherdins signatur"></img>
                            <br/>
                            <p class="subHeading">Doktor i entreprenörskap | Expert på ekonomiska ekosystem</p>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Mikael;