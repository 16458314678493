import './App.css';
import './static/fonts.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Application from './Application';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Application Router={BrowserRouter} />
);