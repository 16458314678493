import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import styled, { keyframes } from 'styled-components';

class Sixth extends React.Component {
    componentDidMount() {
        document.getElementById("bg").style.backgroundColor = "#fdefef";
        document.getElementById("curve").style.backgroundColor = "#fdefef";
    }

    render() {
        return (
            <div class="purple">
                <h2>
                <TypeAnimation
      sequence={[
        'Våra plattformar för sam-utveckling.', // Types 'One'

        () => {
          console.log('Sequence completed'); // Place optional callbacks anywhere in the array
        }
      ]}
      cursor={true}
      repeat={0}
    />
                </h2>
                <Reveal>

                    <p style={{marginTop: "20px"}}>
                      Vi har samlat all våra erfarenheter, forskning och utveckling i plattformar för samutveckling.
                      Plattformar som är anpassade för företagsutveckling, myndigheter som vill lösa hoptrasslade
                      problem, eller kommuner och organisationer som vill arbeta på nya sätt.
                      <br/><br/>
                      Gemensamt för våra plattformar är att arbeta med horisontellt ledarskap för att lösa trassliga
                      problem och bygga nödvändiga lösningar där flera organisationer samarbetar.
                      <br/><br/>
                      För vidare information om våra SAAS plattformar, kontakta Mikael Scherdin.
                    </p>                    
                </Reveal>
            </div>
        )
    }
}

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Reveal = styled.span`
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

export default Sixth;