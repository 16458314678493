import React, { useState } from 'react';

import surrPurple from '../static/surr/purple.mp4';
import surrGreen from '../static/surr/green.mp4';
import surrBlue from '../static/surr/blue.mp4';
import surrPink from '../static/surr/pink.mp4';

import One from '../static/one.mp4';
import Two from '../static/two.mp4';
import Three from '../static/three.mp4';
import Four from '../static/four.mp4';

class Team extends React.Component {
    render() {
        return (
            <div id="kontakt">
                <div class="team">
                    <div class="member">
                        <div class="videos">
                            <video class="surr" playsInline autoPlay loop muted src={surrPurple} type="video/mp4"></video>
                            <video playsInline autoPlay loop muted src={One} type="video/mp4"></video>
                        </div>
                        <div class="text">
                            <h3>Mikael Scherdin</h3>
                            <p>
                                <div class="highlight">Senior forskare och entreprenör. Scherdin har en bred och djup erfarenhet med flera
                                positioner inom näringsliv, forskningsorganisationer, offentliga organisationer, egna
                                större forskningsprojekt, samt med start-ups. Ekonomiska ekosystem,
                                entreprenörskap och innovation är expertfokus.</div>
                                <b>CEO | Grundare</b>
                                <br/>
                                <i>mikael@invitetodo.com</i>
                            </p>
                        </div>
                    </div>

                    
                    <div class="member">
                        <div class="videos">
                            <video class="surr" playsInline autoPlay loop muted src={surrGreen} type="video/mp4"></video>
                            <video playsInline autoPlay loop muted src={Three} type="video/mp4"></video>
                        </div>
                        <div class="text">
                            <h3>Alvin Elfvén</h3>
                            <p>
                                <div class="highlight">
                                    Driver hemsidor och webbtjänster för egen räkning sedan 2020. Jobbar med sökmotoroptimering främst och utvecklar webbportaler i en rad olika former.
                                    
                                </div>
                                <b>Front-end</b>
                                <br/>
                                <i>alvin@invitetodo.com</i>
                            </p>
                        </div>
                    </div>

                    <div class="member">
                        <div class="videos">
                            <video class="surr" playsInline autoPlay loop muted src={surrBlue} type="video/mp4"></video>
                            <video playsInline autoPlay loop muted src={Four} type="video/mp4"></video>
                        </div>
                        <div class="text">
                            <h3>Jonathan Norlund</h3>
                            <p>
                                <div class="highlight">
                                    Civilingenjörsprogrammet i teknisk datavetenskap, med expertfokus på
                                    databashantering, programmering och AI-tillämpning.
                                </div>
                                <b>Back-end</b>
                                <br/>
                                <i>jonathan@invitetodo.com</i>
                            </p>
                        </div>
                    </div>

                    <div class="member">
                        <div class="videos">
                            <video class="surr" playsInline autoPlay loop muted src={surrPink} type="video/mp4"></video>
                            <video playsInline autoPlay loop muted src={Two} type="video/mp4"></video>
                        </div>
                        <div class="text">
                            <h3>Daniel Arvor</h3>
                            <p>
                                <div class="highlight">
                                    Senior rådgivare med ett antal distinkta positioner inom näringslivet, i både start-up
                                    och mer traditionella företag. Arvor har bred erfarenhet inom många områden i
                                    software, hardware, säkerhet, och tillämpningar. Kort sagt en skicklig generalist.
                                </div>
                                <b>Senior rådgivare</b>
                                <br/>
                                <i>daniel@invitetodo.com</i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Team;