import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom'; // Import useLocation

import Logo from '../static/inv_logo.png';

function Menu() {
    const [showMenu, setShowMenu] = useState({ isHidden: true });
    const location = useLocation(); // Get the current location

    function toggleHidden() {
        setShowMenu({ isHidden: !showMenu.isHidden });
    }

    const style = {
        display: showMenu.isHidden ? 'none' : 'block'
    };

    // Conditional style for menu background color
    const menuStyle = {
        backgroundColor: location.pathname === '/' ? 'var(--pink)' : '#5c004f',
    };

    // Get the current date
    const currentDate = new Date();
    const showInnovationsveckan = currentDate <= new Date('2024-10-02');

    // Conditional grid column setup based on the presence of Innovationsveckan
    const gridColumns = showInnovationsveckan ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr';

    return (
        <>
            <Third>
                <div className="menu" style={menuStyle}>
                    <a href="/"><img src={Logo} width="159px" height="auto" alt="InviteToDo vit logo"></img></a>

                    <div className="items" style={{ gridTemplateColumns: gridColumns }}>
                        <a href="/om-oss">Om oss</a>
                        <a href="/case">Case</a>
                        <a href="/produkter">Produkter</a>
                        {showInnovationsveckan && <a href="/innovationsveckan">SKR Innovationsveckan</a>}
                        <a href="https://account.invitetodo.com/apps/accounts/login/">Login</a>
                    </div>

                    <div className="burger" onClick={toggleHidden}>☰</div>

                    <div className="mobileMenu slideDown" id="mobileMenu" style={style}>
                        <a href="/om-oss" onClick={toggleHidden}>Om oss</a>
                        <br />
                        <a href="/case" onClick={toggleHidden}>Case</a>
                        <br />
                        <a href="/produkter" onClick={toggleHidden}>Produkter</a>
                        <br />
                        {showInnovationsveckan && <a href="/innovationsveckan" onClick={toggleHidden}>SKR Innovationsveckan</a>}
                        <br />
                        <a href="https://account.invitetodo.com/apps/accounts/login/" onClick={toggleHidden}>Login</a>
                    </div>
                </div>
            </Third>

            <Outlet />
        </>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const Third = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
`

export default Menu;