import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import casePicture from '../../static/case.webp';
import trasselSide from '../../static/case.webm';

function Case4() {
    useEffect(() => {
        document.title = "Case: Företag, myndigheter och kommun utvecklar logistikkoncept för ny stadsdel. | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={casePicture} width="200px" height="auto" alt="Näringslivsstrategi för tillväxt" />
                <div class="text-wrapper">
                    <h1>Företag, myndigheter och kommun utvecklar logistikkoncept för ny stadsdel.</h1>
                    <p class="subHeading" style={{ marginBottom: '0px' }}>2023</p>
                </div>
            </div>
            </First>

            
            <div class="caseContainer">
                <div class="trasselSide">
                    <video playsInline autoPlay muted src={trasselSide} type="video/mp4"></video>
                </div>
                <Second>
                <div class="caseBody">
                    <h2>Vad var problemet?</h2>
                    <p>
                        En kommun skall bygga en ny stadsdel, men frågorna är många. Man kommer inte framåt.
                        Problemen är många. De är intrasslade i varandra. Olika beslutsvägar blockerar varandra. Bristande
                        kommunikation mellan organisationerna gör att vanlig samverkan blir för simpelt för att kunna lösa
                        problemen på ett effektivt sätt. Det behövs något annat för framdrift för att lösa samägda problem
                        som skall bli samägda lösningar. Man talar själv om att det är ett komplext problem.
                    </p>

                    <h2>Vilken metod använde vi?</h2>
                    <p>
                        TO DO Unravel® | Trassel till Struktur.
                    </p>

                    <h2>Vilka deltog?</h2>
                    <p>
                        Myndigheter, Regional myndighet, flera avdelningar i en kommun, organisation och
                        företag.
                    </p>

                    <h2>Vad blev resultatet?</h2>
                    <p>
                        Ett gemensamt koncept tas fram, som i sin tur strukturerar de olika intrasslade
                        utmaningarna till sammanhängande delmoment som hjälper gruppen framåt. De inleder med att
                        presentera lösningen i de olika organisationerna för att förbereda framdrift. Arbetet med utveckling
                        och implementering pågår fortfarande.
                    </p>
                </div>
                </Second>
            </div>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Case4;