import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import krusidull1 from '../../static/krusidull-white.webp';

function Produkt1() {
    useEffect(() => {
        document.title = "Webbinar | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={krusidull1} width="100px" height="auto" alt="krussidull"></img>
                <div class="text-wrapper">
                    <h1>Webbinar</h1>
                </div>
            </div>
            </First>

            <Second>
            <div class="produktBody">
                <div class="headingContainer">
                    <h2>1</h2>
                </div>
                <p>
                    Vad är horisontellt ledarskap och hur kan man använda det när många parter
                    tillsammans, äger ett problem eller en idé, som måste lösas tillsammans och kanske ägas
                    tillsammans? Material: Boken Horisontellt Ledarskap, 250 sidor. <span class="upper">(Scherdin 2023)</span> Ett FoU arbete över 3 år
                    som utvecklade nya metoder för att lösa komplexa problem. 6 stycken case. Med
                    finansiering av EU.
                </p>
                
                <div class="headingContainer">
                <h2>2</h2>
                </div>
                <p>
                    När allt sitter ihop i ett trassel med många aktörer som äger ett problem, eller idé
                    tillsammans men inte hittar vägen framåt. Har kan vi lösa ett trassel? Broschyr: En liten
                    bok om trassel.
                </p>

                <div class="headingContainer">
                    <h2>3</h2>
                </div>
                <p>
                    Det kan vara roligt men utmanande att arbeta tillsammans med näringslivet på orten, som
                    näringslivsansvarig. Hur skall man arbeta, metodiskt? Vi berättar om case där vi tillfört
                    metoder och arbetssätt för att underlätta arbetet med tillväxt på mindre orter, där
                    utmaningarna kan vara invecklade och besvärliga. Material: Genomförda <a href="/case">case</a>.
                </p>
                
            </div>
            </Second>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Produkt1;