import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Home from './pages/Home';
import Footer from './components/Footer';
import Om from './pages/Om';

import CasePage from './pages/CasePage';
import Produkter from './pages/Produkter';
import InnovationsVeckan from './pages/InnovationsVeckan';

import Case1 from './pages/Cases/Case1';
import Case2 from './pages/Cases/Case2';
import Case3 from './pages/Cases/Case3';
import Case4 from './pages/Cases/Case4';
import Case5 from './pages/Cases/Case5';

import Produkt1 from './pages/Produkter/Produkt1';
import Produkt2 from './pages/Produkter/Produkt2';
import Produkt3 from './pages/Produkter/Produkt3';
import Produkt4 from './pages/Produkter/Produkt4';

const Application = ({ Router, routerProps }) => {
  return (
    <Router {...routerProps}>
      <Routes>
        <Route path="/" element={<Menu />}>
          <Route index element={<Home />} title="Från trassel till struktur på 9 veckor. | InviteToDo" />

          <Route path="/om-oss" element={<Om />}/>

          <Route path="/case" element={<CasePage />}/>
          <Route path="/case/naringslivsstrategi" element={<Case1 />}/>
          <Route path="/case/fem-kommuner" element={<Case2 />}/>
          <Route path="/case/frustration-till-samutveckling" element={<Case3 />}/>
          <Route path="/case/logistikkoncept" element={<Case4 />}/>
          <Route path="/case/recyclingsystem" element={<Case5 />}/>

          <Route path="/innovationsveckan" element={<InnovationsVeckan />}/>
          
          <Route path="/produkter" element={<Produkter />}/>
          <Route path="/produkter/webbinar" element={<Produkt1/>}/>
          <Route path="/produkter/processtod" element={<Produkt2/>}/>
          <Route path="/produkter/todogrowth" element={<Produkt3/>}/>
          <Route path="/produkter/todounravel" element={<Produkt4/>}/>
        </Route>
      </Routes>

      <Footer/>
    </Router>
  );
};

export default Application;