import React, { useState } from 'react';

class ThirdText extends React.Component {
    render() {
        return (
            <div class="fromBottom leftAlign">
                <h2>Digitalt stöd för sam-utveckling.</h2>

                <p>
                    <b>
                    Vi har samlat all vår erfarenhet, forskning och utveckling i en plattform för samutveckling.
                    </b>
                    <br/><br/>
                    Med den underlättas långsiktigt arbete med företagsutveckling, myndigheter som vill lösa
                    hoptrasslade problem, eller kommuner och organisationer som vill arbeta på nya sätt.
                    <br/><br/>
                    Vill du veta mer om våra erfarenheter av horisontellt ledarskap för att lösa trassliga problem?
                    <br/><br/>
                    Eller hur organisationer redan löst trassliga problem och utvecklat komplexa idéer tillsammans?
                </p>
            </div>
        )
    }
}

export default ThirdText;