import React, { useState } from 'react';

class Tb3 extends React.Component {
    render() {
        return (
            <div class="animation green">
                <h2>Verktyg för genomförande.</h2>
                <p>
                    <b>
                    Vår plattform för samutveckling nöjer sig inte med att bara utveckla.
                    </b>
                    <br/><br/>
                    Nya lösningar måste också användas. När nya idéer står inför genomförande behövs gediget arbete
                    framåt. Vi har verktyg även för den delen – så att det blir av. Så att det blir verkstad (och inte bara
                    post-it lappar som ramlar ner).
                </p>
            </div>
        )
    }
}

export default Tb3;