import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from "react";

import krusidull1 from '../static/krussidull1.png';
import krusidull2 from '../static/krussidull2.png';
import krusidull3 from '../static/krussidull3.png';
import krusidull4 from '../static/krussidull4.png';

function Produkter() {
    useEffect(() => {
        document.title = "Produkter | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <div class="text-wrapper">
                    <h1>Produkter & tjänster</h1>
                    <p>InviteToDo erbjuder en rad produkter och tjänster.</p>
                </div>
            </div>
            </First>
            <Second>
            <div class="pageText">
                <div class="four-columns">
                    <a href="/produkter/webbinar">
                        <div class="caseExcerpt">
                            <h2>Webbinar</h2>
                            <p>
                            Vi erbjuder webbinar för att presentera nya arbetssätt för kommuner, regioner, och myndigheter.
                            </p>

                            <img src={krusidull1} width="100%" height="auto" alt="krussidull"/>

                            <a href="/produkter/webbinar">Läs</a>
                        </div>
                    </a>

                    <a href="/produkter/processtod">
                        <div class="caseExcerpt">
                            <h2>Processtöd</h2>
                            <p>
                            Detta nioveckorsprogram erbjuder en steg-för-steg-process för att omvandla komplexa och intrasslade problem, där många parter som företag, kommuner, myndigheter och organisationer är inblandade, till välstrukturerade lösningar.
                            </p>

                            <img src={krusidull2} width="100%" height="auto" alt="krussidull"/>

                            <a href="/produkter/processtod">Läs</a>
                        </div>
                    </a>

                    <a href="/produkter/todogrowth">
                        <div class="caseExcerpt">
                            <h2>TO DO Growth®</h2>
                            <p>
                            TO DO Growth är en digital plattform utvecklad för att främja tillväxt i lokalt näringsliv, särskilt för näringslivsansvariga inom kommuner och regionorganisationer där traditionell samverkan inte är tillräcklig. 
                            </p>

                            <img src={krusidull3} width="100%" height="auto" alt="krussidull"/>

                            <a href="/produkter/todogrowth">Läs</a>
                        </div>
                    </a>

                    <a href="/produkter/todounravel">
                        <div class="caseExcerpt">
                            <h2>TO DO Unravel®</h2>
                            <p>        
                                TO DO Unravel är en plattform utvecklad för företag, samverkansorganisationer, myndigheter, regionorganisationer och kommuner. För att lösa 
                                komplexa problem, speciellt de som är "fast" i organisationernas strukturer och hindrar utvecklingen av gemensamma lösningar. 
                            </p>

                            <img src={krusidull4} width="100%" height="auto" alt="krussidull"/>

                            <a href="/produkter/todounravel">Läs</a>
                        </div>
                    </a>
                </div>

                
            </div>
            </Second>

            
        </div>
    );
}
const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`
    
export default Produkter;    