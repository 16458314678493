import React, { useState } from 'react';

class Tb1 extends React.Component {
    render() {
        return (
            <div class="animation pink">
                <h2>Verktyg för att lösa trassel.</h2>
                <p>
                    <b>
                    Vi har verktygen för att lösa komplexa utmaningar eller trassel.
                    </b>
                    <br/><br/>
                    Ett trassel som flera organisationer delar och måste lösa behöver andra metoder. Steg för steg löser
                    vårt horisontella arbetssätt upp det som sitter fast.
                </p>
            </div>
        )
    }
}

export default Tb1;