import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import styled, { keyframes } from 'styled-components';

class First extends React.Component {
    componentDidMount() {
        document.getElementById("bg").style.backgroundColor = "var(--lightpink)";
        document.getElementById("curve").style.backgroundColor = "var(--lightpink)";
    }

    render() {
        return (
            <div class="pink leftAlign">

                <h2>
                                <TypeAnimation
                                sequence={[
                        'När kommuner, företag och myndigheter behöver samutveckla.', // Types 'One'

                        () => {
                            console.log('Sequence completed'); // Place optional callbacks anywhere in the array
                        }
                    ]}
                    cursor={true}
                    repeat={0}
                    />
                </h2>
                <Reveal>
                    <p style={{marginTop: "20px"}}>
                        <b>Vi har specialiserat oss på tilltrasslade utmaningar. Trassel som känns komplexa och svåra, med
                        problem eller idéer som måste lösas tillsammans. Utmaningar som kräver samutveckling över
                        organisationsgränser.</b>
                        <br></br><br></br>
                        Exempel på horisontella utmaningar är: agenda 2030, hållbarhetsproblem, innovationsarbete med
                        många parter, näringslivsutveckling eller infrastrukturprojekt, där det krävs mer än samverkan.
                        <br></br><br></br>
                        Att lyckas med att lösa komplexa problem med många inblandade, kräver goda strukturer och bra
                        processarbete för att bygga nya gemensamma vägar framåt.
                        <br></br><br></br>
                        Vi har en plattform för att lösa trassel.
                    </p>
                </Reveal>
            </div>
        )
    }
}

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Reveal = styled.span`
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

export default First;