import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import styled, { keyframes } from 'styled-components';

class Third extends React.Component {
    componentDidMount() {
        document.getElementById("bg").style.backgroundColor = "#fdefef";
        document.getElementById("curve").style.backgroundColor = "#fdefef";
    }

    render() {
        return (
            <div class="purple leftAlign">
                <h2>
                <TypeAnimation
      sequence={[
        'Strukturerad kreativitet skapar framdrift.', // Types 'One'

        () => {
          console.log('Sequence completed'); // Place optional callbacks anywhere in the array
        }
      ]}
      cursor={true}
      repeat={0}
    />
                </h2>
                <Reveal>

                    <p style={{marginTop: "20px"}}>
                      <b>
                      Vi har samlat erfarenheter, processer och verktyg, som driver utveckling.
                      </b>
                      <br/><br/>
                      När många parter måste arbeta tillsammans med komplexa utmaningar krävs ordning och reda. Vi
                      har idéer om nytt ledarskap och kunderna har kreativiteten, för att skapa pigga lösningar på det som
                      fastnat, eller det som bara är otydligt och ostrukturerat.
                    </p>                    
                </Reveal>
            </div>
        )
    }
}

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Reveal = styled.span`
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

export default Third;