import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import krusidull2 from '../../static/krussidull2-white.webp';

function Produkt2() {
    useEffect(() => {
        document.title = "Processtöd | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={krusidull2} width="100px" height="auto" alt="krussidull"></img>
                <div class="text-wrapper">
                    <h1>Processtöd</h1>
                </div>
            </div>
            </First>

            <Second>
            <div class="produktBody">
                <div class="headingContainer">
                    <h2>1. Från Trassel till Struktur på nio veckor.</h2>
                </div>
                <p>
                    <b>För vem:</b> När många olika företag och organisationer samäger ett problem eller en
                    lösning, men saknar den gemensamma vägen framåt.
                    <br/><br/>
                    <b>Vad:</b> När man har ett tilltrasslat problem som involverar många olika parter, samtidigt.
                    Kanske både företag, kommuner, myndigheter och organisationer måste lösa problem
                    tillsammans och kanske också äga lösningen tillsammans över lång tid. Här hjälper vi till
                    med en process i nio steg från trassel till struktur på nio veckor.
                </p>
                
                <div class="headingContainer">
                <h2>2. Näringslivstillväxt: steg I</h2>
                </div>
                <p>
                    <b>För vem:</b> När näringslivsansvariga vill utveckla det lokala näringslivet med verifierade
                    verktyg, processer och metoder, i samarbete med företagen.
                    <br/><br/>
                    <b>Vad:</b> Att utveckla ett näringsliv tillsammans med företag är en långsiktig investering för
                    en kommun. Vi har en hel plattform med verktyg, metoder och arbetssätt för långsiktigt
                    arbete. Men var skall man börja? Beroende på hur mycket som redan finns på plats och
                    hur utvecklat samarbetet är med näringslivet, kan vi kick-starta ett långsiktigt arbete på
                    olika sätt.
                </p>
                
            </div>
            </Second>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Produkt2;