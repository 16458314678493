import React, { useState } from 'react';

import bg from '../static/postit.webp';
import arrow from '../static/arrow.png';

import FirstText from './lower-texts/FirstText';
import SecondText from './lower-texts/SecondText';
import ThirdText from './lower-texts/ThirdText';

class Individ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {id: "1"};
    }

    handleArrowClick(action){

        if(action === 'next'){
            if(this.state.id < '3'){
                this.setState((prevState) => ({ id: (+prevState.id + 1).toString()}));
            } else {
                this.setState( { id: "1"});
            }

        }

    }

    render() {
        return (
            <div class="postIt" style={{ backgroundImage: `url(${bg})` }}>
                <div class="postItContainer">
                    <div class="textContainer">

                    
                        {(this.state.id === "1") && <FirstText />}
                        {(this.state.id === "2") && <SecondText />}
                        {(this.state.id === "3") && <ThirdText />}
                        <br/><br/>

                        <img src={arrow} onClick={() => this.handleArrowClick('next')} class="rotate" width="100%" height="auto" alt="pink arrow icon"></img>
                    </div>

                    
                </div>
            </div>
        )
    }
}

export default Individ;