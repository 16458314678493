import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import styled, { keyframes } from 'styled-components';

class Fourth extends React.Component {
    componentDidMount() {
        document.getElementById("bg").style.backgroundColor = "var(--lightpink)";
        document.getElementById("curve").style.backgroundColor = "var(--lightpink)";
    }

    render() {
        return (
            <div class="pink">

                <h2>
                                <TypeAnimation
                                sequence={[
                        'Vi tror på individen som kraft.', // Types 'One'

                        () => {
                            console.log('Sequence completed'); // Place optional callbacks anywhere in the array
                        }
                    ]}
                    cursor={true}
                    repeat={0}
                    />
                </h2>
                <Reveal>
                    <p style={{marginTop: "20px"}}>
                    Att vara intraprenör är att göra det entreprenörer gör fast inom organisationer. Vissa människor
                    drivs av lust att skapa och utveckla idéer, produkter, tjänster och affärer dygnets alla timmar.
                    <br/><br/>
                    Den stora fördelen med intraprenörskap jämfört med entreprenörskap, är att intraprenören har
                    tillgång till en fungerande organisation som erbjuder ekonomiska och praktiska möjligheter.
                    <br/><br/>
                    Det är hos intraprenören den slumrande resursen finns. De flesta organisationer har vad som krävs i
                    form av kraftfull förnyelse, initiativförmåga, för att mobilisera omvärlden ständiga krav på
                    förändringar.
                    <br/><br/>
                    Många söker utanför organisationen, när lösningen i själva verket finns hos dem själva. Att
                    organisera, strukturera och systematisera ett konstant flöde av idéer är lösningen, både på en
                    strategisk och taktisk nivå.
                    </p>
                </Reveal>
            </div>
        )
    }
}

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Reveal = styled.span`
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

export default Fourth;