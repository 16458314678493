import React, { useState } from 'react';

import Tb1 from '../components/programs/Tb1';
import Tb2 from '../components/programs/Tb2';
import Tb3 from '../components/programs/Tb3';

import Tb1img from '../static/beta1.png';
import Tb2img from '../static/beta2.png';
import Tb3img from '../static/beta3_shadow.png';

import arrow from '../static/arrow.png';

class Program extends React.Component {
    constructor(props) {
        super(props);
        this.state = {id: "1"};
    }

    handleArrowClick(action){

        if(action === 'next'){
            if(this.state.id < '3'){
                this.setState((prevState) => ({ id: (+prevState.id + 1).toString()}));
            } else {
                this.setState( { id: "1"});
            }

        }

    }

    render() {
        return (
            <div id="verktyg">
                <section class="curved" id="curve"></section>
            
                <div class="container">
                    <div class="programs">
                        <div id="a">
                            <img style={(this.state.id === "1") ? { display: "block" } : { display: "none" }} src={Tb1img} class="animation" alt="Toolbox1" width="100%" height="auto"></img>
                            <img style={(this.state.id === "2") ? { display: "block" } : { display: "none" }} src={Tb2img} class="animation" alt="Toolbox2" width="100%" height="auto"></img>
                            <img style={(this.state.id === "3") ? { display: "block" } : { display: "none" }} src={Tb3img} class="animation" alt="Toolbox3" width="100%" height="auto"></img>
                        </div>

                        <div id="b">
                            {(this.state.id === "1") && <Tb1 />}
                            {(this.state.id === "2") && <Tb2 />}
                            {(this.state.id === "3") && <Tb3 />}
                        </div>

                        <img id="c" src={arrow} onClick={() => this.handleArrowClick('next')} class="arrow" width="100%" height="auto" alt="Rosa InviteToDo arrow"></img>
                    </div>
                </div>
            </div>
        )
    }
}

export default Program;