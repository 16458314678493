import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import krusidull2 from '../../static/krussidull2-white.webp';
import todogrowth from '../../static/todogrowth.jpg';

function Produkt3() {
    useEffect(() => {
        document.title = "TO DO Growth | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={todogrowth} width="100px" height="auto" alt="TO DO Growth logo"></img>
                <div class="text-wrapper">
                    <h1>Plattform TO DO Growth® | Nu skapar vi tillväxt.</h1>
                </div>
            </div>
            </First>

            <Second>
            <div class="produktBody">
                <h2>För vem</h2>
                <p>
                    Näringslivsansvariga på en kommun eller en regionorganisation – där frågor
                    måste lösas tillsammans när vanlig samverkan inte räcker till.
                </p>
                <h2 style={{marginTop: "20px"}}>Vad</h2>
                <p>
                    TO DO Growth, är utvecklad för att skapa tillväxt i lokalt näringsliv i samarbete
                    mellan företag och offentliga organisationer. Vår digitala plattform möjliggör att flera
                    olika organisationer arbetar på distans, fast tillsammans. Våra digitala verktyg och
                    processer strukturerar och skapar framdrift. Att alla kan arbeta på distans, gör
                    plattformen speciellt lämpad när en kommun vill arbeta tillsammans med många
                    företag samtidigt för att lösa ett problem, utveckla en ny näringslivsstrategi eller
                    utarbeta vägar framåt - tillsammans. Plattformen har flera funktioner och arbetssätt
                    för att skapa stabila tillväxtmönster.
                    <br/><br/>
                    TO DO Growth, är framtagen i ett forskningsarbete tillsammans med Region
                    Gävleborg, Tillväxtverket och Gävle Högskola, med EU-finansiering.
                </p>
                <h2 style={{marginTop: "20px"}}>Hur</h2>
                <p>
                Det långsiktiga arbetet kan startas med processhjälp från Invite TO DO. Men
                    målet med TO DO Growth-plattformen är att offentliga organisationer tränas och
                    utbildas för att själva skapa tillväxt på ett strukturerat sätt över tid.
                </p>
                
                
                
            </div>
            </Second>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Produkt3;