import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useEffect } from 'react';

import casePicture from '../../static/case.webp';
import trasselSide from '../../static/case.webm';

function Case3() {
    useEffect(() => {
        document.title = "Case: 20 Företag går från frustration till samutveckling. | InviteToDo";
    }, []);

    return (
        <div class="pagePadding">
            <First>
            <div class="case">
                <img src={casePicture} width="200px" height="auto" alt="Näringslivsstrategi för tillväxt" />
                <div class="text-wrapper">
                    <h1>20 Företag går från frustration till samutveckling.</h1>
                    <p class="subHeading" style={{ marginBottom: '0px' }}>2023</p>
                </div>
            </div>
            </First>

            
            <div class="caseContainer">
                <div class="trasselSide">
                    <video playsInline autoPlay muted src={trasselSide} type="video/mp4"></video>
                </div>
                <Second>
                <div class="caseBody">
                    <h2>Vad var problemet?</h2>
                    <p>
                        Ett antal företag kan inte växa i ett län på grund av att för lite råvara för sin produktion av
                        högkvalitativa trävaror. Företagen hindras från tillväxt och arbete med utveckling och innovation
                        hämmas. Många parter äger problemet. Lösningen måste utvecklas gemensamt, men man saknar
                        arbetssätten för att arbeta över organisationsgränser. Det är också många väldigt olika
                        organisationer, som myndigheter, företag, kommun och intresseorganisationer med helt olika
                        synsätt och mål. Problemen har funnits under en längre tid och många olika parter har försökt lösa
                        problemen under många år, men ingen har hittat vägen framåt. Frustrationen var därför stor.
                    </p>

                    <h2>Vilken metod använde vi?</h2>
                    <p>
                        TO DO Growth® | Näringslivstillväxt.
                    </p>

                    <h2>Vilka deltog?</h2>
                    <p>
                        En mycket blandad grupp med representanter från myndigheter, flera företag,
                        kommuner, intresseorganisationer, startar en första utvecklingsprocess, tillsammans.
                    </p>

                    <h2>Vad blev resultatet?</h2>
                    <p>
                        Företagen har idag bildat en gemensam ekonomisk förening för att utvecklas tillsammans.
                        Hela arbetet startade med att strukturera upp problemkomplexet på ett metodiskt sätt,
                        steg för steg. Arbetet har fortsatt över flera år och innefattat innovationsarbete tillsammans.
                        Företagen har nu nya värdekedjor tillsammans, utvecklat gemensamma produkter och tjänster och
                        tar aktiva steg vidare - tillsammans.
                    </p>
                </div>
                </Second>
            </div>
        </div>
    )
}

const animation = keyframes`
    0% { opacity: 0; filter: blur(10px); }
    100% { opacity: 1; filter: blur(0px); }
`

const First = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-fill-mode: forwards;
`

const Second = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export default Case3;